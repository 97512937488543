.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-tabs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
}

.App-content {
  padding-top: 56px; /* Adjust this value to match the height of the Tabs component */
}

.App-logo {
  height: 60px;
  vertical-align: middle;
}

.capitalize {
  text-transform: capitalize;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.SyndiBets-table .summary-table {
  width: 35%;
  max-width: 100%; /* ensures it doesn't overflow its container */
  margin: auto; /* optional, for centering if needed */
}

.logout-button {
  position: fixed;
  right: 10px;
  top: 10px;
  background-color: #fff;  /* Match tab background color */
  color: #000000;          /* Match tab text color */
  padding: 6px 16px;       /* Similar padding to Material-UI tabs */
  font-size: 0.875rem;     /* Standard Material-UI tab font size */
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  border-radius: 4px;      /* Slight rounding of corners */
  outline: none;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #f5f5f5; /* Slightly darker on hover */
}
