/* Container for input and button */
.input-button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the space between the input and the button */
  margin-bottom: 20px;
  width: 600px;
  margin: 20px auto;
}


/* Styling for the TextField label */
.bet-size-input label {
  color: #757575; /* Or any other color that provides good contrast */
}

/* Styling for the TextField label */
.bet-size-input .MuiInputLabel-root {
  color: #333; /* Ensure label is visible */
}

/* Styling for the TextField input */
.bet-size-input .MuiInputBase-input {
  color: #333; /* Text color for the input */
  background-color: #fff; /* Background color for the input */
  border-radius: 4px; /* Optional border radius */
  padding: 8px; /* Optional padding */
  border: 1px solid #ced4da; /* Add border to match the design */
}

/* Styling for the bet size percentage input */
.bet-size-input {
  padding: 10px 12px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 8px; /* Spacing between the input and the button */
  font-size: 1rem;
  line-height: 1.5;
  width: auto; /* Adjust as needed */
}

/* Styling for the recalculate button */
.recalculate-button {
  padding: 10px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.recalculate-button:hover {
  background-color: #0069d9;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Styling for the summary section */
.summary-section {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #212529;
  width: 600px; /* Adjust as needed */
  display: inline-block; /* Makes the div only as wide as it needs to be */
}

.summary-item {
  margin-bottom: 0.5rem; /* 8px */
  font-weight: 400; /* Normal font weight */
}

.TrifectaResults-table {
  margin: 0 auto;
}

/* Container for the controls with light background */
.controls-container {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #212529;
  width: 600px; /* Adjust as needed */
  display: inline-block; /* Makes the div only as wide as it needs to be */
}

/* Style adjustments for the input-button container */
.input-button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
}

/* Styling for the TextField */
.bet-size-input .MuiInputBase-input {
  color: #495057; /* Darker text for better contrast */
  background-color: #fff; /* White background for the input */
  border: 1px solid #ced4da; /* Standard border */
  padding: 8px 12px; /* Padding inside the input */
  border-radius: 4px; /* Rounded corners for the input */
}

/* Label color for better contrast */
.bet-size-input label.Mui-focused {
  color: #495057;
}

/* Overrides the root color of the label */
.bet-size-input .MuiInputLabel-root {
  color: #495057;
}