.SyndiBets-table {
  width: 100%;
  margin: 0 auto;
  background-color: #282c34;
  color: white;
}

.DataGrid-cell {
  border-bottom: 1px solid white;
  background-color: white;
}

.header {
  font-weight: bold !important;
}

.summary-table .MuiDataGrid-columnHeaders {
  display: none;
}

/* Additional global styles, if needed */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left; /* Align text to the start, which is typically left */
  background-color: #fff;
}

/* Styles for the winner and loser rows */
.winner-true {
  background-color: #e8f5e9; /* Light green */
}

.winner-false {
  background-color: #ffebee; /* Light red */
}
